// ---------------------------
// Helpers
// ---------------------------

.img-wrapper {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.position-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}