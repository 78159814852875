// ---------------------------
// Buttons Module
// ---------------------------

button, input[type="submit"] {
    transition: all .3s ease-in-out;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    padding: 0;
    text-decoration: none !important;
    letter-spacing: inherit;

    @include hover-focus-active {
        text-decoration: none !important;
        outline: 0 !important;
    }
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;    
    font-weight: bold;
    text-decoration: none !important;
    text-transform: uppercase;
    padding: .8rem 2rem;
    @extend .rounded;

    &--shadowed {
        box-shadow: 0px 1px 14px rgba(black, 0.29);

        @include media-breakpoint-up(lg) {
            padding: 0.625rem 0.95rem;
            box-shadow: 0px 1px 22px rgba(black, 0.29);
        }
    }
}

.btn-icon {
    width: 3.19rem;
    height: 3.19rem;
    @extend .rounded-circle;

    > i {
        font-size: 2rem;
    }
}

.btn-floating-action {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1001;
    border-radius: 0 !important;
}

@each $color, $value in $theme-colors {
    .btn-icon-#{$color} {
        padding: 0;
        @include button-variant($value);
    }

    .btn-#{$color}--reverse {
        @include button-variant-reverse($value);
    }
}
