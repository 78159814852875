// ---------------------------
// Variables
// ---------------------------

// Colors
// ---------------------------

$dark-red: #a2135b;
$red: #d4134a;
$blue: #0b93d2;
$green: #95c11f;
$orange: #f28c00;
$black: #000000;
$grey-25: #303030;
$grey-35: #555555;
$grey-50: #707070;
$grey-60: #999999;
$grey-65: #aaaaaa;
$grey-70: #b4b4b4;
$grey-80: #cccccc;
$grey-85: #d7d7d7;
$grey-90: #efeded;
$grey-92: #f5f5f5;
$grey-95: #fafafa;

$theme-colors: (
    'primary': $blue,
    'secondary': $dark-red,
    'danger': $red,
    'success': $green,
    'info': $blue,
    'warning': $orange,
    'black': $black,
    'light-grey': $grey-80,
    'grey': $grey-60,
    'dark-grey': $grey-25,    
    'grey-35': $grey-35,
    'grey-50': $grey-50,
    'grey-60': $grey-60,
    'grey-65': $grey-65,
    'grey-70': $grey-70,
    'grey-80': $grey-80,
    'grey-85': $grey-85,
    'grey-90': $grey-90,
    'grey-92': $grey-92,
    'grey-95': $grey-95,
);

$body-bg: white;
$body-color: $grey-25;
$link-color: $grey-35;
$link-hover-color: darken($grey-35, 10);

// Font family
// ---------------------------

$font-family-base: "Roboto", "Helvetica Neue", Arial, sans-serif;
$font-family-oswald: "Open Sans", "Helvetica Neue", Arial, sans-serif;
$font-family-open-sans: "Open Sans", "Helvetica Neue", Arial, sans-serif;

// Spacing
// ---------------------------

$spacers: (
    3: 0.9375rem,
    4: 1.2rem,
    5: 1.6rem,
    6: 1.8rem,
    7: 2rem,
    8: 2.3rem,
    9: 2.7rem,
    10: 3rem,
    11: 3.5rem,
    12: 4rem,
    13: 4.5rem,
    14: 5.3rem,
    15: 6rem,
    16: 8rem
);

// Font sizes
// ---------------------------

$font-sizes: (
    1: 0.75rem,
    2: 0.875rem,
    3: 1rem,
    4: 1.2rem,
    5: 1.45rem,
    6: 1.75rem,
    7: 2rem
);

// Path
// ---------------------------
$font-path: "/assets/fonts";
$fa-font-path: $font-path + "/fontawesome";
$img-path: "/assets/img";