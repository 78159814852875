// ---------------------------
// Dropdown Module
// ---------------------------

.dropdown-menu {
    border-radius: 0;
    border: 1px solid $grey-90;
    padding: 0;

    > li {
        .dropdown-item {
            padding: 0.4rem 1rem;

            > i {
                min-width: 1.5rem;
            }
        }

        &:not(:last-child) {
            border-bottom: 1px solid $grey-90;
        }
    }
}