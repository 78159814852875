/* You can add global styles to this file, and also import other style files */
@import "assets/scss/variables.scss";
@import "~ngx-toastr/toastr";
@import "~bootstrap/scss/bootstrap";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~ngx-bootstrap/datepicker/bs-datepicker.css";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "assets/scss/style";