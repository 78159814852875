// ---------------------------
// Links Module
// ---------------------------

a,
.link {
  position: relative;
  font-weight: bold;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  color: $body-color;

  @include hover-focus-active {
    text-decoration: none;
  }
}

.link {
  @extend .text-break;
  
  &::after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: inherit;
  }
}
