// ---------------------------
// Size Utils
// ---------------------------

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $prop, $abbrev in (width: w) {
            @each $index, $size in $sizes {
                .#{$abbrev}#{$infix}-#{$index} {
                    #{$prop}: $size !important;
                }
            }
        }

        @each $prop, $abbrev in (max-width: mw) {
            @each $index, $size in $sizes {
                .#{$abbrev}#{$infix}-#{$index} {
                    #{$prop}: $size !important;
                }
            }
        }

        @each $prop, $abbrev in (height: h) {
            @each $index, $size in $sizes {
                .#{$abbrev}#{$infix}-#{$index} {
                    #{$prop}: $size !important;
                }
            }
        }

        @each $prop, $abbrev in (max-height: mh) {
            @each $index, $size in $sizes {
                .#{$abbrev}#{$infix}-#{$index} {
                    #{$prop}: $size !important;
                }
            }
        }

        .cover#{$infix}-landscape {
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            transform: translateX(-50%) translateY(-50%);
            max-width: 1px;
            max-height: none;
        }

        .cover#{$infix}-portrait {
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            transform: translateX(-50%) translateY(-50%);
            max-width: none;
            max-height: 1px;
        }
    }
}
