// ---------------------------
// Buttons Mixin
// ---------------------------

@mixin button-variant($color, $hover-color: darken($color, 10%)) {
  background-color: $color;
  color: black;

  @include hover-focus-active {
    background-color: $hover-color;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    background-color: color-yiq($color);
    // Remove CSS gradients if they're enabled
    @if $enable-gradients {
      background-image: none;
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    border-color: $hover-color;
    background-color: $hover-color;
    color: $color;
  }
}

@mixin button-variant-reverse($color, $hover-color: $color) {
  background-color: white;
  border-color: white;
  color: $color;

  @include hover-focus-active() {
    border-color: $hover-color;
    background-color: $hover-color;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    background-color: color-yiq($hover-color);
    // Remove CSS gradients if they're enabled
    @if $enable-gradients {
      background-image: none;
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    border-color: $hover-color;
    background-color: $hover-color;
  }
}
