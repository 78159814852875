// ---------------------------
// Tabs Module
// ---------------------------

.tab-container {
    .nav-tabs {        
        display: flex;
        position: fixed;
        z-index: 105;
        width: 100%;
        align-items: center;
        justify-content: space-around;
        border: 0;
        padding: .5rem;
        background-color: $grey-90;
        margin-bottom: 1.2rem !important;

        .nav-item-underline {            
            background-color: transparent;
            @extend .col;

            .nav-link {
                background-color: transparent;
                padding: .5rem 0;
                border: 0;                

                @include hover-focus-active {
                    border: 0 !important;
                }
            }

            @include hover-focus-active {
                .nav-link {
                    border: 0 !important;
                }
            }

            &:not(:last-child) {
                border-right: 1px solid $grey-50;
            }

            &.active {
                .nav-link {
                    color: $dark-red;

                    .badge {
                        @extend .badge-secondary;
                    }
                }
            }
        }
    }

    .tab-content {
        @extend .pt-13;
    }
}
