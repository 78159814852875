// ---------------------------
// Modals Module
// ---------------------------

.modal {
    .modal-dialog {
        .modal-content {
            .modal-header {
                .close {
                    opacity: 0.8;
                }
            }
        }
    }
}
