// ---------------------------
// Typography Module
// ---------------------------

h1, .h1 {
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: inherit;
    line-height: normal;
    font-family: $font-family-open-sans;

    @include media-breakpoint-up(md) {
        font-size: 1.815rem;
    }
}

h2, .h2 {
    font-size: 1.3rem;
    font-weight: 700;
    letter-spacing: inherit;
    line-height: normal;
    font-family: $font-family-open-sans;

    @include media-breakpoint-up(md) {
        font-size: 1.5rem;
    }
}

h3, .h3 {
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: inherit;
    letter-spacing: inherit;
    line-height: normal;
    font-family: $font-family-open-sans;

    @include media-breakpoint-up(md) {
        font-size: 1.4rem;
    }
}

h4, .h4 {
    font-size: 1.1rem;
    font-weight: 600;
    letter-spacing: inherit;
    letter-spacing: inherit;
    line-height: normal;
    font-family: $font-family-open-sans;

    @include media-breakpoint-up(md) {
        font-size: 1.25rem;
    }
}

.font-family-oswald {
    font-family: $font-family-oswald;
}

.font-family-open-sans {
    font-family: $font-family-open-sans;
}

.font-family-roboto {
    font-family: $font-family-base;
}

.font-weight-light {
    font-weight: 300;
}

.font-weight-medium {
    font-weight: 500;
}

.font-weight-semi-bold {
    font-weight: 600;
}

.text-underline {
    text-decoration: underline;
}

.text-normal {
    text-transform: none !important;
}