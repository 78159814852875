// ---------------------------
// Layout
// ---------------------------

body {
    &.top-bar {
        background-color: $grey-95;
    }
}

.app-content {
    transition: all 0.3s ease-in-out;

    &.fullscreen {
        display: flex;
        flex-direction: column;
        position: fixed;
        height: 100%;
        width: 100%;

        main {
            height: 100%;
        }      
    }

    &-sidebar {
        padding-top: 3rem;

        footer {
            display: none;

            @include media-breakpoint-up(lg) {
                display: block;
            }
        }

        &.swiped-header {
            .circle-visio {
                @include media-breakpoint-down(md) {
                    top: 0;
                }
            }

            @include media-breakpoint-down(md) {
                padding-top: 0;
            }
        }
    }

    .logo {
        max-width: 130px;
    }

    main {
        position: relative;
        z-index: 1;
    }
}
