// ---------------------------
// Font-Size Utils
// ---------------------------

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $prop, $abbrev in (font-size: fs) {
            @each $index, $size in $font-sizes {
                .#{$abbrev}#{$infix}-#{$index} {
                    #{$prop}: $size !important;
                }
            }
        }
    }
}
