// ---------------------------
// CT Fonts Module
// ---------------------------

@font-face {
  font-family: "mlp-font";
  src: url($font-path + "/mlp-font/mlp-font.eot?i5ce70");
  src: url($font-path + "/mlp-font/mlp-font.eot?i5ce70#iefix") format("embedded-opentype"),
    url($font-path + "/mlp-font/mlp-font.ttf?i5ce70") format("truetype"),
    url($font-path + "/mlp-font/mlp-font.woff?i5ce70") format("woff"),
    url($font-path + "/mlp-font/mlp-font.svg?i5ce70#mlp-font") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.mlp-font {
  display: inline-block;
  vertical-align: middle;
  font-family: "mlp-font" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 1.4rem;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.mlp-file {
    &:before {
      content: "\e903"; 
    }
  }

  &.mlp-file-audio {
    &:before {
      content: "\e901"; 
    }
  }

  &.mlp-file-presentation {
    &:before {
      content: "\e902"; 
    }
  }

  &.mlp-file-spreadsheet {
    &:before {
      content: "\e900"; 
    }
  }

  &.mlp-file-text {
    &:before {
      content: "\e904"; 
    }
  }

  &.mlp-file-video {
    &:before {
      content: "\e905"; 
    }
  }

  &.mlp-file-archive {
    &:before {
      content: "\e907"; 
    }
  }

  &.mlp-folder-move {
    &:before {
      content: "\e906"; 
    }
  }
}
