// ---------------------------
// Quill Module
// ---------------------------

.ql-toolbar.ql-snow {
    border: 0;
    border-bottom: 1px solid #999;
    padding: 0 0 0.5rem 0;

    + .ql-container.ql-snow {
        border: 0;
        font-size: 0.9rem;
        font-family: $font-family-base;
    }
}