// ---------------------------
// Form Module
// ---------------------------

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input::placeholder {
  color: $body-color !important;
  opacity: 1;
}

input::-webkit-input-placeholder {
  color: $body-color !important;
  opacity: 1;
}

input::-moz-placeholder {
  color: $body-color !important;
  opacity: 1;
}

input:-ms-input-placeholder {
  color: $body-color !important;
  opacity: 1;
}

input::-ms-input-placeholder {
  color: $body-color !important;
  opacity: 1;
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
select,
.form-control {
  border: 0;
  padding: 0.375rem 0;
  border-bottom: 2px solid $body-color;
  border-radius: 0;
  font-weight: 400;
  outline: none;
  background: none;

  &:disabled {
    background: none;
    color: map-get($map: $theme-colors, $key: "secondary");
  }

  @include hover-focus-active {
    border-color: $body-color;
    background: none;
    box-shadow: none;
  }
}

.form-check {
  padding-left: 0;

  .form-check-input {
    display: none;

    & + .form-check-label {
      &::before {
        content: "\f0c8";
        font-family: "Font Awesome 5 Free";
        font-weight: 400;
        font-size: 1.2rem;
      }
    }

    &:checked + .form-check-label {
      &::before {
        content: "\f14a";
        font-weight: 900;
      }
    }
  }
}

.form-group {
  position: relative;
  margin-bottom: 1.4rem;

  > label {
    position: absolute;
    top: 0.5rem;
    transition: all 0.3s ease-in-out;
    z-index: 1060;
  }

  > .float-label {
    & ~ label {
      color: $grey-50;
      font-size: 0.875rem;
      top: -0.875rem;
    }
  }
}

.ng-select {  
  .ng-select-container {
    border: 0;
    padding: 0.375rem 0;    
    border-bottom: 2px solid $body-color !important;
    border-radius: 0;
    font-weight: 400;
    outline: none; 
    box-shadow: none !important;
    background: none;
    
    .ng-value-container {
      padding-left: 0;

      .ng-value {
        .img-wrapper {
          width: 1.8rem;
          background-position: center;
        }
      }
    }

    .ng-arrow-wrapper {
      display: none;
    }

    &::after {
      content: "\f078";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
    }
  }

  .ng-dropdown-panel {
    z-index: 1070;

    .ng-dropdown-panel-items {
      .ng-option {
        color: $body-color;

        .img-wrapper {
          width: 1.8rem;
          background-position: center;
        }

        &.ng-option-selected, &.ng-option-selected.ng-option-marked, &.ng-option-marked {
          background-color: rgba($color: $blue, $alpha: .1);
          color: $body-color;
        }

        &:not(:last-of-type) {
          border-bottom: 1px solid $grey-90;
        }
      }
    } 
  }

  &.ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        .ng-placeholder {
          top: auto;
          padding: 0;
        }

        .ng-value {
          padding: 0.1rem 0.375rem;
          margin-bottom: 5px;
          background-color: $grey-90;
          border-radius: 1.2rem;
          display: flex;
          border: 0;
          align-items: center;

          .ng-value-icon {
            font-size: 1rem;
            font-weight: 700;
            border: 0;

            @include hover-focus-active {
              background: none;
            }
          }
        }
      }
    }
  }

  &.ng-select-opened {
    > .ng-select-container {
      background: none;
    }
  }
}

.bs-datepicker {
  &.theme-default {
    .bs-datepicker-head {
      background-color: $green;
    }
    
    .bs-datepicker-body {
      table {
        td {
          span.selected, 
          &.selected span, 
          span[class*="select-"]:after, 
          &[class*="select-"] span:after {
            background-color: $green;
          }
        }
      }
    }     
  }
}
