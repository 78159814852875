// ---------------------------
// Base
// ---------------------------

body {
  letter-spacing: 1px;
  font-size: 1rem;
  font-weight: 400;
}

p {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
